/* eslint-disable max-len */
import 'src/pages/confirmation/index.scss';
import React, { useEffect } from 'react';
import { useApiState, useCart, useConfig, useTheme } from 'src/hooks';
import Icon from 'src/components/atoms/icon';

import { FILTERED_PLANS, PAGES, PAYMENT } from 'src/constants';
import SEO from 'src/components/atoms/seo';
import Separator from 'src/components/atoms/separator';
import { navigate } from 'gatsby';

import type { Plan } from 'src/helpers/transformers/api/_types';

import { format } from 'date-fns';

export const DATA_LAYER_NAME = 'adobeDataLayer';

const { HOME } = PAGES;

export default () => {
  const { backgroundColor } = useTheme('primary');
  const { confirmationNumber } = useApiState();
  const { confirmationPageFollowUp } = useConfig();
  const { cartTotal, discount, plansInCart, rate } = useCart();

  const push = (event: any) => (window as any)?.[DATA_LAYER_NAME]?.push?.(event);
  const getBrand = () => location.hostname?.split('.')?.reverse()?.[1] ?? '';

  const pushPurchase = (product: any, transaction: any, payment: any) => push({
    event: 'purchase',
    global: {
      brand: getBrand(),
      eventAction: 'purchase'
    },
    payment: payment,
    product: product,
    transaction: transaction
  });

  useEffect(() => {
    !plansInCart?.length && navigate(HOME);
    const product: any = [];
    let totalCartQuantity = 0;
    let selectedPalns: any = plansInCart;
    if (sessionStorage.getItem(FILTERED_PLANS.LINK_PLANS)) {
      selectedPalns = JSON.parse(sessionStorage.getItem(FILTERED_PLANS.LINK_PLANS) || plansInCart);
    }

    if (sessionStorage.getItem(FILTERED_PLANS.PLANS)) {
      const filteredPlans = JSON.parse(sessionStorage.getItem(FILTERED_PLANS.PLANS) || '');
      // eslint-disable-next-line @typescript-eslint/no-inferrable-types
      selectedPalns.forEach((plan: Plan) => {
        const planQuantity = plan.quantity ? plan.quantity : 0;
        totalCartQuantity += planQuantity;
        const filteredPlan = filteredPlans?.find((p: any) => p.id === plan.id);
        product.push({
          cancelReturnFee: 0.00,
          category: 'ANCILLARY SERVICE',
          discount: plan.discountPrice ? Number((plan.price.monthlyAmount - plan.discountPrice.monthlyDiscountAmount).toFixed(2)) : 0,
          flags: plan.promotion,
          gridPosition: filteredPlan?.featuredPosition ? filteredPlan.featuredPosition : 1,
          name: plan.name,
          planRateType: 'fixed',
          price: plan.price.monthlyAmount,
          priceTotal: plan.price.monthlyAmount,
          quantity: planQuantity,
          sku: plan.id,
          term: 12,
          type: plan.category
        });
      });
    }

    const transaction = {
      currency: 'USD',
      discount: discount,
      id: confirmationNumber,
      quantity: totalCartQuantity,
      salesTax: 0.00,
      shipping: 0.00,
      total: cartTotal
    };

    const payment = {
      creditCardCompany: '',
      date: format(new Date(), 'MM/dd/yyyy'),
      dueDate: format(new Date(), 'MM/dd/yyyy'),
      failureReason: '',
      method: sessionStorage.getItem(PAYMENT.METHOD),
      paymentAmount: cartTotal,
      paymentPortion: 'Full',
      result: 'Success',
      totalDue: 0.00
    };

    pushPurchase(product, transaction, payment);

    return () => window.location.reload();
  }, []);

  const Items = () => (
    <div className='confirmation--details--items'>
      {plansInCart?.map((plan: Plan) => (
        <div className='confirmation--details--items--item' key={plan.id}>
          <span className='confirmation--details--items--item--group'>
            <span className='confirmation--details--items--item--group--qty'>{plan.quantity} x </span>
            <span className='confirmation--details--items--item--group--name'>{plan.name}</span>
          </span>
          <span>
            <span>{plan?.price.formattedAmount}</span>
          </span>
        </div>
      ))}
    </div>
  );

  const Discount = () => (
    <div className='confirmation--details--discount'>
      <span>Discount</span>
      <span>
        <span className='confirmation--details--discount--amount'>-${discount}</span>
        <span>{rate}</span>
      </span>
    </div>
  );

  const Total = () => (
    <div className='confirmation--details--total'>
      <span>Total</span>
      <span>
        <span>${cartTotal}</span>
        <span className='confirmation--details--rate'>{rate}</span>
      </span>
    </div>
  );

  return (
    <div className='confirmation'>
      <div className='confirmation--info'>
        <Icon className='confirmation--info--icon' color={backgroundColor} type='check' height='56' width='56' />
        <div className='confirmation--info--headline'>{`You're all set!`}</div>
        <div className='confirmation--info--subhead'>{`Your order id is ${confirmationNumber}`}</div>
        <div className='confirmation--info--subhead'>You will receive an order confirmation email shortly.  </div>
      </div>
      <div className='confirmation--details'>
        <div className='confirmation--details--title'>Order details</div>
        <Separator />
        <Items />
        <Separator />
        {discount && <Discount />}
        <Total />
      </div>
      <div className='confirmation--text'>{confirmationPageFollowUp}</div>
    </div>
  );
};

export const Head = () => <SEO pageTitle='Confirmation' />;
